<script>
import NoData from "@/views/app/CustomComponents/NoData/NoData.vue";
import GenericModal from "@/views/app/Generic/modal.vue";
import TermineingForm from "@/views/app/Kanban/Termineinhaltung/TermineingForm.vue";

export default {
  name: "Termineinhaltung",
  components: {TermineingForm, GenericModal, NoData},
  props: {
    entityId: {
      type: String,
      required: true,
    },
    relation: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      get_deadline_column_url: '/boards/deadline-column-board',
      columns_deadline_board: [],
      loading: false,
      initialState: undefined,
      showAction: false,
      activeElement: null,
      tableColumn: [{column_key: 'start_column', column_name: 'Start Column'}, {
        column_key: 'start_column',
        column_name: 'End Column'
      }, {
        column_key: 'deadline_value',
        column_name: 'Deadline Value'
      }]
    }
  },
  methods: {
    async getDealineColumnBoard() {
      this.loading = true
      let response = await this.$http.get(this.get_deadline_column_url, {
        params: {
          board_id: this.entityId,
        }
      })
      this.loading = false
      this.columns_deadline_board = response.data.data
    },
    async actionDeadlineColumnBoard(action) {
      console.log("this is the value to delete", this.activeElement)
      console.log("this is the action", action)
      if (action === 'delete') {
        this.loading = true
        let payload = {
          start_column_id: this.activeElement.start_column.column_id,
          end_column_id: this.activeElement.end_column.column_id,
          board_id: this.$route.params.id
        }
        this.$http.delete(this.get_deadline_column_url + `?start_column_id=${payload.start_column_id}&end_column_id=${payload.end_column_id}&board_id=${payload.board_id}`,).then((response) => {
          console.log("this is the response of the server after delete", response)
          this.columns_deadline_board = this.columns_deadline_board.filter((x) => x.deadline_column_board_id != this.activeElement.deadline_column_board_id)

        }).catch((error) => {
          this.$bvToast.toast(`An error has occurred while processing your request. We apologize for the inconvenience.We are currently working to resolve this issue. Please try again later.`, {
            title: 'Warning',
            autoHideDelay: 6000,
            variant: 'warning',
            appendToast: true
          })
        }).finally(() => {
          this.loading = false
          this.$refs["modal-1"].hide()
        })

      } else if (action === 'edit') {
        this.initialState = this.activeElement
        this.$refs.termineinModal.openModal()
      }
    },
    activeShowAction(e, action, activeElement) {
      this.showAction = action !== 'leave'
      // this.showAction = true
      const actionContainer = document.querySelector('#actionColumn')
      const termineingContainer = document.querySelector('.termineingContainer').getBoundingClientRect()
      if (action === 'enter') {
        this.activeElement = activeElement
        actionContainer.style.left = (e.pageX - 10) - termineingContainer.left + 'px'
        actionContainer.style.top = (e.pageY - 10) - termineingContainer.top + 'px'
        actionContainer.style.position = 'absolute'
      }
    }
  },
  mounted() {
    this.getDealineColumnBoard()
  }
}
</script>

<template>
  <div class="termineingContainer position-relative">
    <b-table-simple striped responsive :busy.sync="loading">
      <template #table-busy>
        <div class="text-center text-danger">
          <b-spinner class="align-middle"/>
          <strong class="ml-1">{{ $t('table~message~loading') }}</strong>
        </div>
      </template>
      <b-thead>
        <b-th v-for="(column, index) in tableColumn" :key="index">
          {{ column.column_name }}
        </b-th>
      </b-thead>
      <b-tbody>
        <NoData v-if="columns_deadline_board.length ===0"/>
        <b-tr v-else v-for="(row,i) in columns_deadline_board" :key="i">
          <b-td @mouseenter="(e) => activeShowAction(e,'enter',row)" @mouseleave="(e)=>activeShowAction(e,'leave')"
                class="cursor-pointer">{{ row.start_column.column_name || '-------|----' }}
          </b-td>
          <b-td @mouseenter="(e) => activeShowAction(e,'enter',row)" @mouseleave="(e)=>activeShowAction(e,'leave')">
            {{ row.end_column.column_name || '-------|----' }}
          </b-td>
          <b-td @mouseenter="(e) => activeShowAction(e,'enter',row)" @mouseleave="(e)=>activeShowAction(e,'leave')">
            {{ row.display_time_unit === 'day' ? (row.deadline_value / 24) : row.deadline_value || '-------|----' }}
            {{ row.display_time_unit === 'day' ? 'Days' : 'Hours' }}
          </b-td>
          <!--          <b-td>{{ row.company_id || '-&#45;&#45;&#45;&#45;&#45;&#45;|-&#45;&#45;&#45;&#45;&#45;&#45;' }}</b-td>-->
          <!--          <b-td>{{ row.company_id || '-&#45;&#45;&#45;&#45;&#45;&#45;|-&#45;&#45;&#45;&#45;&#45;&#45;' }}</b-td>-->
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div v-show="showAction" @mouseenter="(e)=>activeShowAction(e,'stop_move')" id="actionColumn"
         class="align-center justify-content-center">
      <b-avatar button class="mr-1" variant="danger" size="25" v-b-modal.modal-1>
        <feather-icon icon="Trash2Icon" size="15"/>
      </b-avatar>
      <b-avatar button variant="warning" size="25" @click="()=>actionDeadlineColumnBoard('edit')">
        <feather-icon icon="EditIcon" size="15"/>
      </b-avatar>
    </div>
    <b-modal id="modal-1" ref="modal-1">
      <template #modal-title>
        <h6 class="text-white"> Delete meeting deadline</h6>
      </template>
      <p v-if="activeElement">
        Please confirm your intention to delete the meeting deadlines
        <strong>{{ activeElement.start_column.column_name }} - {{ activeElement.end_column.column_name }}.</strong>
        This action is irreversible and will result in the loss of all associated data and evaluation.
      </p>
      <template #modal-footer>
        <div class="w-100 justify-content-end d-flex">
          <b-button
            variant="primary"
            size="sm"
            @click="show=false"
          >
            Cancel
          </b-button>
          <b-button
            variant="success"
            size="sm"
            class="ml-2"
            @click="() => actionDeadlineColumnBoard('delete')"
          >
            <b-spinner v-if="loading" style="width: 14px; height: 14px"/>
            Yes
          </b-button>
        </div>
      </template>
    </b-modal>
    <TermineingForm v-show="initialState !== undefined" ref="termineinModal" :initial-state="initialState"/>
  </div>
</template>

<style lang="scss">
#modal-1___BV_modal_header_ {
  background: #D51130;
}
</style>
