<script>
import Field from "@/views/app/Generic/Field.vue";
import vSelect from "vue-select";
import {initialState} from "@/views/app/CustomComponents/DataTable/state/data-table-store";

export default {
  name: "TermineingForm",
  components: {vSelect, Field},
  props: ['initialState'],
  data: () => {
    return {
      create: true,
      colums_board: [],
      get_columns_url: '/boards/columns',
      save_deadline_url: '/boards/deadline-column-board',
      server_data_send: {
        start_column_id: null,
        end_column_id: null,
        board_id: null,
        deadline_value: null,
        display_time_unit: "hour",
        company_id: null,
        customergroup_id: null
      },
      loading: false,
      endColumn: []
    }
  },
  computed: {
    startedColumn() {
      return this.colums_board.map((item) => ({label: item.column_name, value: item.column_id.toString()}))
    }
  },
  watch: {
    'server_data_send.start_column_id'(newValue, oldValue) {
      const dataColumnSelected = this.colums_board.find((x) => x.column_id === parseInt(newValue))
      this.endColumn = this.colums_board.filter((x) => x.column_id !== newValue && x.rank_order > dataColumnSelected.rank_order).map((item) => ({
        label: item.column_name,
        value: item.column_id
      }))
    },
    initialState(newvalue, oldValue) {
      this.server_data_send = {
        ...this.server_data_send,
        board_id: newvalue.board.board_id,
        end_column_id: newvalue.end_column.column_id.toString(),
        start_column_id: newvalue.start_column.column_id.toString(),
        display_time_unit: newvalue.display_time_unit,
        deadline_value: newvalue.display_time_unit === 'day' ? (newvalue.deadline_value / 24) : newvalue.deadline_value
      }
    }
  },
  methods: {
    openModal(create) {
      this.$refs.termineinModal.show()
    },
    async getColumsBoard() {
      let response = await this.$http.get(this.get_columns_url, {
        params: {
          board_id: this.$route.params.id
        }
      })

      this.colums_board = response.data.data
    },
    async saveDeadline() {
      this.loading = true
      let payload = {
        ...this.server_data_send,
        board_id: this.$route.params.id,
        deadline_value: this.server_data_send.display_time_unit === 'day' ? this.server_data_send.deadline_value * 24 : this.server_data_send.deadline_value
      }

      if (this.initialState !== undefined) {
        await this.$http.put(this.save_deadline_url, payload).then((response) => {
          return response
        }).catch((error) => console.log("this is the error save meeting deadline", error)).finally(() => {
          this.loading = false
          this.$refs.termineinModal.hide()
        })
      } else {
        await this.$http.post(this.save_deadline_url, payload).then((response) => {
          return response
        }).catch((error) => console.log("this is the error save meeting deadline", error)).finally(() => {
          this.loading = false
          this.$refs.termineinModal.hide()
        })
      }
    }
  },
  mounted() {
    this.getColumsBoard()
  }

}
</script>

<template>
  <b-modal ref="termineinModal" hide-footer :title="create ? 'Add a new Termineinhaltung' : 'Update Termineinhaltung'">
    <validation-observer ref="form" v-slot="{ passes }" tag="div" class="my-2">
      <b-form @submit.prevent="passes(submit)">
        <b-row>
          <b-col cols="12">
            <v-select v-model="server_data_send.start_column_id"
                      placeholder="Select the start column"
                      :clearable="true"
                      :options=" startedColumn" transition="" label="label"
                      class="w-100" :reduce="i => i.value.toString()"/>
          </b-col>
          <b-col cols="12 mt-2">
            <v-select v-model="server_data_send.end_column_id"
                      placeholder="Select the end column "
                      :clearable="true"
                      :disabled="!server_data_send.start_column_id"
                      :options="endColumn" transition="" label="label"
                      class="w-100" :reduce="i => i.value.toString()"/>
          </b-col>
          <b-col cols="12" class="mt-2 d-flex">
            <div style="flex-grow: 1">
              <b-form-input v-model="server_data_send.deadline_value" placeholder="Enter duration "></b-form-input>
            </div>
            <div>
              <b-form-select v-model="server_data_send.display_time_unit"
                             :options="[{value:'hour',text:'Hours'},{value:'day',text:'Days'}]"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-button variant="primary" block class="mt-2" @click="saveDeadline">
          <b-spinner v-if="loading" small class="mr-50"/>
          {{ initialState !== undefined ? 'Update' : 'Save' }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<style scoped>

</style>
